$primary: #0026dc;
$success: #24e68f;
$warning: #fdca53;
$danger: #fd6d4e;

$logo1: #03fb18;
$logo2: #03c136;

$chart1: #34cc33;
$chart2: #339965;
$chart3: #ffff00;
$chart4: #ffbf00;
$chart5: #c00201;

$dark-blue: #22293f;
$light-pink: #db99ee;
$light-blue: #87d7ea;

$body-color: $black;
$link-color: $black;

$card-bg: #faf9f8;
$card-hover-color: rgba($white, 1);
$card-active-color: #efefef;
$card-border-width: 0;

$topbar-bg-color: transparent;
$topbar-border-color: #edeff2;

$nav-link-color: #495057;
$nav-tabs-link-active-bg: transparent;
$nav-tabs-link-active-color: black;

$toast-background-color: rgba($white, 1);
$toast-header-background-color: rgba($white, 1);

$form-check-input-width: 2em;
$form-switch-width: 4em;

$custom-colors: (
	"primary": $primary,
	"success": $success,
	"warning": $warning,
	"danger": $danger,
	"dark-blue": $dark-blue,
	"light-pink": $light-pink,
	"light-blue": $light-blue,

	"gray-500": $gray-500,

	"chart1": $chart1,
	"chart2": $chart2,
	"chart3": $chart3,
	"chart4": $chart4,
	"chart5": $chart5,

	"logo1": $logo1,
	"logo2": $logo2,
);

body {
	margin: 0;
	padding: 0;
	background-color: #ecefeb !important;
}

.dropdown-no-caret::after {
	display: none !important;
}

.topbar {
	border-bottom-width: 2px;
	border-bottom-style: solid;
	border-bottom-color: $topbar-border-color !important;
	background-color: $topbar-bg-color;
}

.form-floating > .form-control::placeholder {
	color: revert !important;
}
.form-floating > .form-control:not(:focus)::placeholder {
	color: transparent !important;
}

.list-card:hover {
	background-color: $card-hover-color;
}
.list-card:active {
	background-color: $card-active-color;
}

.skeleton::after {
	width: 100%;
	animation: skeleton-loading 2s linear infinite alternate;
}

.skeleton-text {
	visibility: hidden;
	position: relative;
}

.skeleton-text::after {
	visibility: visible;
	position: absolute;
	content: " ";
	width: 90%;
	height: 90%;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	border-radius: $border-radius;
}

.skeleton-text-title::after {
	width: 70%;
}
.skeleton-text-title.skeleton-center::after {
	left: 15%;
}
.skeleton-text-content::after {
	width: 90%;
}
.skeleton-text-content.skeleton-center::after {
	left: 5%;
}

.skeleton-button {
	visibility: hidden;
	position: relative;
}
.skeleton-button::after {
	visibility: visible;
	position: absolute;
	content: " ";
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	border-radius: $border-radius;
}

@keyframes skeleton-loading {
	0% {
		background-color: rgba($black, 0.05);
	}
	20% {
		background-color: rgba($black, 0.05);
	}
	50% {
		background-color: rgba($black, 0.1);
	}
	80% {
		background-color: rgba($black, 0.05);
	}
	100% {
		background-color: rgba($black, 0.05);
	}
}

.tox-tinymce {
	border-width: $border-width !important;
	border-color: $border-color !important;
	border-radius: $border-radius !important;
}
.tox-tinymce--disabled {
	border-color: $border-color !important;
}

.tox-tinymce.focus {
	border-color: #86b7fe !important;
	box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.tox-tinymce--disabled .tox-edit-area__iframe,
.tox-tinymce--disabled .tox-statusbar,
.tox-tinymce--disabled .tox-editor-header {
	background-color: $input-disabled-bg !important;
}
.tox-tbtn--disabled .tox-toolbar__primary {
	background-color: $input-disabled-bg !important;
}

.tox .tox-dialog--width-lg {
	max-width: 400px !important;
}

.react-datepicker-wrapper {
	width: 100%;
}
.react-datepicker__header {
	background-color: $card-bg;
	border-bottom-color: $border-color;
}
.react-datepicker__navigation {
	margin-top: $spacer * 0.5;
}
.react-datepicker__current-month {
	font-weight: 500;
	text-transform: uppercase;
}
.react-datepicker__navigation-icon::before {
	top: 12px;
}
.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
	width: 2.2rem;
	text-transform: uppercase;
	font-weight: 300;
}
.react-datepicker__triangle {
	left: 50% !important;
	transform: translateX(-4px) !important;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
	border-bottom-color: $border-color;
}
.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::after {
	border-bottom-color: $card-bg !important;
}

.input-group .react-datepicker-wrapper {
	position: relative;
	flex: 1 1 auto;
	width: 1%;
	min-width: 0;
}
.input-group .react-datepicker-wrapper .form-control {
	margin-left: -1px;
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}

.rct-icon {
	min-width: 24px;
}
.react-checkbox-tree ol ol {
	padding-left: 32px;
}

.form-control.disabled {
	background-color: $input-disabled-bg !important;
}

.line-1 {
	display: -webkit-box;
	line-clamp: 1;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
	word-break: break-all;
}

.bg-card {
	background: $card-bg;
}

.border-card {
	border: 1px solid $card-border-color;
}

.bg-body {
	background: $body-bg;
}

.bg-nav-active {
	background: $card-active-color;
}

.title-border {
	width: 150px;
	height: 3px;
	border-radius: 3px;
	background-color: $primary;
	margin-top: 15px;
}

.tooltip-inner {
	max-width: 350px !important;
}

.sortable-item.sortable-item--dragging {
	background-color: $white;
	border-top: 1px solid $border-color;
	opacity: 0.8;
	box-shadow: var(--bs-box-shadow) !important;
	cursor: ns-resize;
}

.react-apex-chart {
	min-height: auto !important;
}

.jodit-container:not(.jodit_inline).jodit_disabled {
	background-color: #e9ecef !important;
}
.jodit-container:not(.jodit_inline).jodit_disabled .jodit-toolbar__box:not(:empty):not(:empty) {
	background-color: #e9ecef !important;
}
.jodit-container:not(.jodit_inline).jodit_disabled .jodit-workplace {
	opacity: 1 !important;
	background-color: #e9ecef !important;
}
.jodit-container {
	border-color: #e3e3e3 !important;
	border-radius: 0.375rem;
	overflow: hidden;
}
.jodit-ui-group_separated_true:not(:last-child):not(.jodit-ui-group_before-spacer_true):after {
	border-color: #e3e3e3 !important;
}
.jodit-merge-tags-dropdown {
	list-style: none;
	margin: 0;
	padding: 0;
	border: 1px solid #e3e3e3 !important;
	background: white;
	position: absolute;
	z-index: 1000;
	max-height: 200px;
	overflow-y: auto;
	box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.jodit-merge-tag-item {
	padding: 8px 12px;
	cursor: pointer;
	position: relative;
}

.jodit-merge-tag-item:hover {
	background: #f0f0f0;
}

.jodit-merge-tag-submenu {
	display: none;
	position: absolute;
	left: 100%;
	top: 0;
	margin-left: 5px;
	border: 1px solid #ccc;
	background: white;
	box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.jodit-merge-tag-submenu .jodit-merge-tag-item {
	white-space: nowrap;
}

.jodit-popup {
	box-shadow: var(--bs-box-shadow-sm) !important;
	border: 1px solid rgba(0, 0, 0, 0.175) !important;
	border-radius: 0.375rem !important;
	overflow: hidden;
}
.jodit-popup .jodit-popup__content {
	padding: 0.5rem 0 !important;
}
.jodit-popup .jodit-popup__content .jodit-toolbar-button__button {
	padding: 0.5rem 1rem !important;
	color: black;
}
.jodit-popup .jodit-popup__content .jodit-toolbar-button__button:hover {
	background-color: #f8f9fa !important;
}
.jodit-popup .jodit-popup__content .jodit-toolbar-editor-collection .jodit-toolbar-button {
	margin: 0 !important;
	border: 0 !important;
}
